import React from "react";
import { graphql } from "gatsby";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  ImageCollection
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>Fannin Mart Restaurant | Gallery</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Gallery"}
              tagline={""}
              images={[data.heroImages.map(({ url }) => url)[3]]}
              showMap={false}
              ctas={[data.ctas.phone]}
              ctaInverted={false}
              ctaColor={"secondary"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={false}
              fullWidth={true}
              parallax={true}
              containerAs={"none"}
              height={"100vh"}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                as={"gallery"}
                carouselOptions={{
                  autoScroll: false,
                  autoScrollTime: 2000
                }}
                centered={false}
                cutUnevenRows={true}
                disable={"none"}
                header={"Gallery"}
                images={data.galleryImages.map(({ url }) => url)}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            phone {
              link
              text
              internal
            }
          }
          heroImages {
            url
          }
          businessName
          about
          galleryImages {
            url
          }
          reviews {
            name
            content
            link
          }
          phone
          address {
            street
            city
            state
            zip
          }
          hours {
            open
            close
            day
            label
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          heroImages {
            url
          }
          galleryImages {
            url
          }
        }
      }
    }
  }
`;
